<template>
  <el-row type="flex" justify="center" align="middle" class="body-login">
    <el-col :span="4">
      <img
        src="https://public-files-listening.us-chi1.upcloudobjects.com/assets/logo.png"
      />
      <el-form class="login">
        <el-row type="flex" justify="center" align="middle" class="login__wrap">
          <el-col>
            <el-row type="flex" justify="center">
              <el-col :span="18">
                <input-login
                  :label="$t('view.login.username')"
                  v-model="auth.username"
                  @enter="submit" />
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col :span="18">
                <input-login
                  :label="$t('view.login.password')"
                  v-model="auth.password"
                  :password="true"
                  @enter="submit" />
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col align="center">
                <el-button
                  native-type="submit"
                  type="success"
                  plain
                  @click="submit"
                  :disabled="!isValid"
                  v-loading.fullscreen.lock="loading">
                  {{ $t('view.login.signin') }}
                  </el-button>
              </el-col>
            </el-row>
            <el-row class="link-external">
              <el-col align="center">
                <el-link href="https://www.tooldata.io" type="success" :underline="false">
                  {{ $t('view.login.link') }}
                </el-link>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import InputLogin from '@/components/auth/input-login'

export default {
  name: 'Login',
  data () {
    return {
      auth: {
        username: null,
        password: null
      },
      loading: false
    }
  },
  computed: {
    isValid () {
      return (
        this.auth.username &&
        this.auth.username.length >= 5 &&
        this.auth.password &&
        this.auth.password.length >= 5
      )
    }
  },
  methods: {
    async submit (event) {
      event.preventDefault()

      const vm = this
      try {
        vm.loading = true

        const response = await vm.$http.post('auth', vm.auth)
        vm.$message.success(vm.$t('view.login.welcome', response.data.profile))

        vm.$auth.addProfile(response.data.profile)
        vm.$auth.updateHeaders(response.data.accessToken)
        sessionStorage.setItem('perPage', response.data.profile.perPage)
        // vm.setPerpage(response.data.profile.perPage)

        vm.loading = false
        if (response.data.profile.isStaff) { // si es el admin
          vm.$router.push({ name: 'AdminHome' })
        } else {
          // vm.checkProjects(response.data.profile)
        }
      } catch (error) {
        console.log(error)

        vm.loading = false
        if (error.status === 403) {
          vm.$message.error(vm.$t('view.login.signin_failed'))
        } else {
          vm.$message.error(vm.$t('view.login.signin_failed'))
        }
      }
    }
  },
  components: {
    InputLogin
  }
}
</script>
<style lang="sass">
.body-login
  color: white
  height: 100vh

  .login
    background-color: #232933
    border-radius: 5px
    box-shadow: 3px 3px 3px 3px #DADADA
    color: white
    height: 20rem

    .login__wrap
      height: 20rem
      margin-top: 2rem

      button
        margin-top: 1.5rem

    .link-external
      margin-top: 2rem

  img
    max-width: 100%
</style>
