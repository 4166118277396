<template>
  <div class="input-login">
    <label :ref="`${this.id}-label`">
      {{ label }}
    </label>
    <input
      :ref="`${this.id}-input`"
      @input="onInput"
      :type="type"
      :placeholder="label"
      :value="value"
      autocomplete="on" />
  </div>
</template>
<script>
export default {
  name: 'InputLogin',
  props: [
    'label',
    'password',
    'value'
  ],
  data () {
    return {
      id: null
    }
  },
  computed: {
    type () {
      return (this.password) ? 'password' : 'text'
    }
  },
  methods: {
    generateId () {
      return Number.parseInt(Math.random() * 10000)
    },
    effectsInput () {
      const input = this.$refs[`${this.id}-input`]
      const label = this.$refs[`${this.id}-label`]

      if (input.value === '') {
        label.classList.remove('dirty')
      }

      input.addEventListener(
        'keyup',
        () => {
          if (input.value === '') {
            label.classList.remove('on-focus')
            label.classList.remove('dirty')
          } else {
            label.classList.add('on-focus')
            label.classList.remove('dirty')
          }
        },
        false
      )
      input.addEventListener(
        'blur',
        () => {
          if (input.value === '') {
            label.classList.remove('on-focus')
          } else {
            label.classList.remove('on-focus')
            label.classList.add('dirty')
          }
        },
        false
      )
    },
    onInput (event) {
      this.$emit('input', event.target.value)
    }
  },
  beforeMount () {
    this.$set(this, 'id', this.generateId())
  },
  mounted () {
    this.effectsInput()
  }
}
</script>
<style lang="sass">
.input-login
  label
    display: block
    font-size: .8rem
    height: .8rem
    opacity: 0
    padding: 0
    margin: 0
    line-height: .8rem
    visibility: hidden
    transition: visibility 0.01s, opacity 0.01s linear

    &.dirty
      color: gray
      visibility: visible
      opacity: 1

    &.on-focus
      color: #52f17e
      visibility: visible
      opacity: 1

  input
    background-color: transparent
    border: 0
    border-bottom: 1px solid #EEE
    border-radius: 0
    color: white
    font-size: .8rem
    height: 30px
    outline: 0
    padding: 0
    margin-bottom: 1.5rem
    line-height: .8rem
    width: 100%

    &:focus
      border-bottom: 2px solid #52f17e
</style>
